<template>
    <div class="bg-white border-b pb-20">
        <Header v-bind="header" @goHome="() => $emit('goHome')"/>
        <div class="max-w-7xl mx-auto pt-10 lg:px-0 px-4">
<!--            <BackBtn label="Back"/>-->
            <Content v-bind="contentParams"/>
        </div>
    </div>
    <div class="bg-contact lg:pt-20 pt-14">
        <div class="max-w-7xl mx-3 md:mx-auto bg-white py-10 shadow-lg px-4">
            <TextWithBorder :content="history.title" align="center" class="mt-7" size="5xl" weight="semibold" color="gray-700" />
            <div class="flex flex-col items-center justify-center">
                <Text v-for="description in history.descriptions" :key="description" size="" color="gray-500" :content="description" class="text-center mt-10 max-w-4xl leading-8" />
            </div>
        </div>
        <div class="max-w-7xl mx-auto mt-20">
            <TextWithBorder :content="coreParams.title" align="center" class="mt-7 mb-10 md:mb-20" size="5xl" weight="semibold" color="gray-700" />
            <WithBackgroundImage v-for="param in coreParams.contents" :key="param.title" v-bind="param" class="mb-5"/>
        </div>
    </div>
    <Footer v-bind="footer" class="mt-5 md:-mt-5"/>
</template>

<script>
import Header from '../../organisms/Headers/SimpleWithNavigation/SimpleWithNavigation.vue'
import Footer from '../../organisms/Footers/SimpleWithSocialIcons/SimpleWithSocialIcons.vue'
import BackBtn from '../../molecules/Button/BackButton/BackButton.vue'
import Content from '../../organisms/ContentSections/WithTitleDescriptionAndTabs/WithTitleDescriptionAndTabs.vue'
import TextWithBorder from '../../molecules/Text/TextWithTopBorderInVariousPositions/TextWithTopBorderInVariousPositions.vue'
import Text from '../../atoms/Text/Text.vue'
import WithBackgroundImage from '../../molecules/Content/WithBackgroundImage/WithBackgroundImage.vue'
/**
 - Use it to show a About template
 **/
export default {
    components: {
    Header,
    Footer,
    BackBtn,
    Content,
    TextWithBorder,
    Text,
    WithBackgroundImage
},
    props: {
        /**
         * Use it for header
         */
         header: {
            type: Object,
            default: () => {},
        },
        /**
         * Use it for footer
         */
         footer: {
            type: Object,
            default: () => {}
        },
        contentParams: {
            type: Object,
            default: () => {}
        },
        history: {
            type: Object,
            default: () => {}
        },
        coreParams: {
            type: Object,
            default: () => {}
        }
    }
};
</script>
