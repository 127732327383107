
<template>
  <div>
    <div class="sm:hidden">
      <label for="tabs" class="sr-only">Select a tab</label>
      <!-- Use an "onChange" listener to redirect the user to the selected tab URL. -->
      <select id="tabs" name="tabs" class="
          block
          w-full
          focus:ring-primary focus:border-primary
          border-primary
          rounded-md
          border
          py-2 px-2
          outline-none
          text-gray-500
          mb-2
          mt-4
        "
        @change="onSelect(tabs.find(t => t.name === $event.target.value))"
        >
        <option v-for="tab in tabs" :key="tab.name" :selected="tab.current" :value="tab.name">
          {{ tab.name }}
        </option>
      </select>
    </div>
    <div class="hidden sm:block shadow-lg border-primary border-b-2 rounded-lg px-10 py-3">
      <nav class="relative z-0 flex justify-between" aria-label="Tabs">
        <Button v-for="(tab, tabIdx) in tabs" :key="tabIdx" :content="tab.name" variant="primary" :onClick="() => onSelect(tab)"
          :customClass="`bg-primary hover:bg-opacity-90 ${tab.current && 'bg-opacity-70'}`" />
      </nav>
    </div>
  </div>
</template>

<script>
import Button from '../../../atoms/Button/Button.vue'
/**
 * - Use it when show tabs with underline in shadow container
 */
export default {
  components: {
    Button
  },
  props: {
    /**
     * the list of tabs
     */
    tabs: {
      type: Array,
      default: () => [],
      required: true,
    },
    onSelect: {
      type: Function,
      default: () => {}
    }
  },
};
</script>
