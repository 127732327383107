<template>
  <Template
			@goHome="() => $router.push('/')"
			v-bind="{
  	...params,
  	isLoggedIn: state.isLoggedIn
  }"/>

</template>

<script>
import Template from '../components/templates/About/About'
import {c} from '../components/constants'

export default {
  components: {
  	Template
  },
  data () {
    return {
    	params: c.about
    }
  },
}
</script>
