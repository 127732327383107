<template>
    <div class="max-w-7xl mx-auto pt-10">
        <TextWithBorder :content="title" align="center" class="mt-10" size="5xl" weight="semibold" color="gray-700" />
        <div class="flex justify-center">
            <Text size="" color="gray-500" :content="description" class="text-center mt-10 max-w-3xl" />
        </div>
        <img src="../../../../assets/public/images/about-main-image.png" class="w-full" />
<!--        <TabsWithBorderInShadowContainer :tabs="items" class="mx-8" :onSelect="onSelectItem" />-->
<!--        <div class="mx-8 sm:mx-12 px-10 py-10 shadow-lg rounded-lg space-y-4">-->
<!--            <Text size="sm" color="gray-500" v-for="item in tabItems[selectedTab.name]" :key="item" :content="item"-->
<!--                class="leading-6" />-->
<!--        </div>-->
    </div>
</template>

<script>
import TextWithBorder from '../../../molecules/Text/TextWithTopBorderInVariousPositions/TextWithTopBorderInVariousPositions.vue'
import Text from '../../../atoms/Text/Text.vue'
import TabsWithBorderInShadowContainer from '../../../molecules/Tabs/TabsWithBorderInShadowContainer/TabsWithBorderInShadowContainer.vue';
/**
 - Use it to show a content with title, description, logo and tabs
 **/
export default {
    components: {
        TextWithBorder,
        Text,
        TabsWithBorderInShadowContainer
    },
    data() {
        return {
            selectedTab: this.tabs[0],
            items: this.tabs
        }
    },
    methods: {
        onSelectItem(tab) {
            if (tab.current) {
                return
            }
            const items = [...this.items];
            items.find(i => i.current === true).current = false;
            items.find(i => i.name === tab.name).current = true;
            this.items = items;
            this.selectedTab = this.items.find(i => i.current)
        }
    },
    props: {
        /**
         * title
         */
        title: {
            type: String,
            default: ''
        },
        description: {
            type: String,
            default: ''
        },
        /**
         * tabs
         */
        tabs: {
            type: Array,
            default: () => []
        },
        tabItems: {
            type: Object,
            default: () => { }
        }
    }
};
</script>
