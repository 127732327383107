<template>
  <div class="flex flex-col" :class="`items-${align === 'center' ? align : align === 'right' ? 'end' : 'start'}`">
    <div class="h-1 w-20 bg-primary mb-3"/>
    <Text :size="size" :color="color" :content="content" :align="align" :transform="transform" :weight="weight" :element="element" :customClass="customClass"/>
  </div>
</template>

<script>
import Text from '../../../atoms/Text/Text.vue';
/**
- Use it to show a text with top border in various positions
**/

export default {
  components: { Text },
  props: {
    /**
    sets size of text from xs to 9xl. Default is base
    */
    size: {
      type: String,
      default: "",
    },
    /**
    sets color of text.
    */
    color: {
      type: String,
      default: "",
    },
    /**
    sets content of text.
    */
    content: {
      type: String,
      default: "",
    },
    /**
    sets alignment of text i.e. left,right,center
    */
    align: {
      type: String,
      default: "",
    },
    /**
    sets tranformation options of text i.e. uppercase, lowercase etc.
    */
    transform: {
      type: String,
      default: "",
    },
    /**
    sets decoration options of text i.e. underline, strikethrough etc.
    */
    decoration: {
      type: String,
      default: "",
    },
    /**
    used to add additional classes to text like letter spacing
    */
    customClass: {
      type: String,
      default: "",
    },
    /**
    sets font weight.
    */
    weight: {
      type: String,
      default: "medium",
    },
    /**
     * switch between span and div. default is set to div
     */
    element: {
      type: String,
      default: "div",
    },
  },
};
</script>
