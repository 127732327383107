<template>
	<div class="relative" style="min-height: 500px !important">
		<img :src="image" class="hidden sm:block absolute w-2/3 rounded object-cover" style="height: 400px" :class="contentPosition === 'right' ? 'left-0 top-0' : 'right-0 top-7'"/>
		<div class="bg-white shadow-lg rounded px-4 md:px-10 pb-5 pt-10 w-full md:w-1/2 z-40 block md:absolute" :class="contentPosition === 'right' ? 'mt-0 md:mt-56 right-0' : 'mt-0'">
			<img :src="image" class="md:hidden w-screen rounded object-cover mb-10 rounded-md w-400" style="height: 400px"/>
			<HeaderText :content="title" size="2xl" color="gray-800"/>
			<Text :content="description" size="sm" color="gray-500" class="mt-4 leading-6"/>
		</div>	
	</div>
	
</template>

<script>
import Text from "../../../atoms/Text/Text.vue";
import HeaderText from '../../Text/TextWithTopBorderInVariousPositions/TextWithTopBorderInVariousPositions.vue'
/**
 * - Use it to show a content with background image
 */
export default {
	components: {
		Text, HeaderText
	},
	props: {
		/**
		 * title
		 */
		title: {
			type: String,
			default: ''
		},
		/**
		 * description
		 */
		description: {
			type: String,
			default: ''
		},
		/**
		 * background image
		 */
		image: {
			type: String,
			default: ''
		},
		/**
		 * content panel position
		 */
		contentPosition: {
			type: String,
			default: 'left'
		}
	},
}

</script>
